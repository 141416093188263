$(document).ready(function () {
    $('.modal').modal();

    $("#add").click(function() { 
        $(".clone")
            .last()
            .clone()
            .appendTo($(".opcoes"))
            .find("input").attr("name",function(i,oldVal) {
                return oldVal.replace(/\[(\d+)\]/,function(_,m){
                    return "[" + (+m + 1) + "]";
                });
            });        
        return false;        
    });
    $("input:radio").change(function(){
        if($(this).val() == 'NÃO'){
            $("#justificativa" + $(this).attr('pergunta')).attr('style', '');
        } else {
             $("#justificativa" + $(this).attr('pergunta')).attr('style', 'display:none');

        }
    });
    // $(".instrucoes").change(function(){
    //     console.log($(this).val());
    //     if($(this).val() == 'true'){
    //         $('#pagina'+$(this).attr('instrucao_id')).removeAttr('disabled');
    //     } else {
    //          $('#pagina'+$(this).attr('instrucao_id')).attr('disabled', 'disabled');
    //     }
    // });
    $("#validator").validate({
        submitHandler: function(form) {
        // do other things for a valid form
        form.submit();
    }
    });


    $('#unidade').on('change', function (e) {
            var optionSelected = $("option:selected", this);
            var valueSelected = this.value;
                $.ajax({
                    url: '/unidades/'+ valueSelected ,
                    type: 'GET',
                    dataType: 'json',
                })
                .done(function(data) {
                    console.log(data);
                    var $el = $("#unidade_filha");
                    console.log($el);
                    $el.empty(); // remove old options
                    $.each(data, function(key,value) {
                      $el.append($("<option></option>")
                         .attr("value", key).text(value));
                    }); 
                    $('select').material_select();
                   
                })

            
          });


    $(document).ready(function() {
      $('select').material_select();
        $('.processo').mask('00000.000000/0000-00');
      
    });

});